@import "../../SiteShared.scss";

.main-calendar {
  font-size: 16px;
  height: auto;
  .calendar-header.ui.menu {
    color: $primary-color-light;
    background-color: $primary-color;

    .month-label {
      font-size: 1.5em;
      line-height: 40px;
    }

    .calendar-action.item {
      color: $primary-color-light;
    }
  }

  .calendar-body {
    &.ui.attached.segment {
      max-width: 100%;
    }

    .calendar-dow {
      background-color: $primary-color-light;
      color: $primary-color;
      font-size: 1.25em;
    }
  }

  .events-daily {
    .event-card {
      &.leasera-event {
        background-color: rgba(
          red(#4c158a),
          green(#4c158a),
          blue(#4c158a),
          0.15
        ) !important;
      }
      &.google-calendar-event {
      }
    }
    .event-card.ui.card {
      width: 100%;
      text-align: left;
      background-color: $primary-color-light;
      font-size: 1em;

      .event-delete {
        float: right;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
} //End of calendar container

.days-title {
  color: $primary-color;
  width: 100%;
}

.day {
  min-height: 100px;
  border: $primary-color-light solid 1px;

  &:hover {
    background-color: $accent-color-light;
    .day-number {
      cursor: default;
    }
  }

  &.today {
    background: $accent-color-light;
    .day-number {
      color: $primary-color;
    }
  }

  &.before {
    .day-number {
      color: $primary-color-medium;
    }
  }

  &.different-month {
    opacity: 0.25;
    .day-number {
      color: $primary-color-medium;
    }
  }

  .day-number {
    font-size: 1.25em;
  }

  .event-label {
    opacity: 0.5;
    padding-top: 15px;
  }
}
.dashboard-cal {
  .calendar-dow {
    font-size: 1em !important;
  }
  .main-calendar {
    .seven {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
    // max-height:0px !important;
  }
  .day {
    min-height: 40px !important;
    // max-height:20px;
    border: $primary-color-light solid 1px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    &:hover {
      background-color: $accent-color-light;
      .day-number {
        cursor: default;
      }
    }
    &.chosen {
      border: $accent-color-medium solid 1px;
      background-color: $accent-color-light;
    }
    &.today {
      background: $accent-color-medium;
      .day-number {
        color: $primary-color;
      }
    }

    &.before {
      .day-number {
        color: $primary-color-medium;
      }
    }

    &.different-month {
      opacity: 0.25;
      .day-number {
        color: $primary-color-medium;
      }
    }

    .day-number {
      font-size: 1em;
    }

    .event-label {
      opacity: 0.5;
      padding-top: 0px !important;
    }
  }
}

.tourHourOnCalendar {
  font-size: 13;
  text-align: left;
  padding: 15px 0;
}

.tour-hour-border {
  border-bottom: 1px dashed #000000;
  margin: 15px;
}

.colors-description {
  display: flex;
  height: 50px;

  .color-item {
    display: flex;
    flex-direction: row;
    margin: 0 30px;

    .color-leasera {
      width: 50px;
      height: 30px;
      background-color: #f1eded;
    }

    .color-google {
      width: 50px;
      height: 30px;
      background-color: rgba(red(#4c158a), green(#4c158a), blue(#4c158a), 0.15);
    }

    .title {
      margin-left: 10px;
      font-weight: 600;
      font-size: 14px;
      font-family: inherit;
      line-height: 30px;
    }
  }
}

.create-event-modal {
  background-color: rgba(0, 0, 0, 0.6);
}
